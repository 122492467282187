import React from "react";
import NotFoundSvg from "../svgs/NotFoundSvg";
import {ArrowLeft} from "react-feather";
import BaseComponent from "../components/BaseComponent";

class NotFound extends BaseComponent {

  render() {
    return <div className="min-h-screen flex items-center bg-colors-cyan-800 dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-600">
      <div className="w-full flex flex-col lg:flex-row items-center justify-center space-y-16 lg:space-y-0 space-x-8 2xl:space-x-0">
        <div data-aos="fade-up" className="w-full lg:w-1/2 flex flex-col items-center justify-center lg:px-2 xl:px-0 text-center">
          <p className="text-7xl md:text-8xl lg:text-9xl font-bold tracking-wider text-gray-300">404</p>
          <p className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-wider text-gray-300 mt-2">Page Not Found</p>
          <p className="text-lg md:text-xl lg:text-2xl text-gray-500 my-12">Sorry, the page you are looking for could not be found.</p>
          <a href="/"
             className="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 rounded transition duration-150"
             title="Return Home">
            <ArrowLeft/>
            <span>Return Home</span>
          </a>
        </div>
        <div className="w-1/2 lg:h-full flex lg:items-end justify-center p-4">
          <div data-aos="fade-up" className="w-full text-blue-600">
            <NotFoundSvg/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default NotFound;
