import React, {createRef} from "react";
import BaseComponent from "../../../components/BaseComponent";
import shortid from "shortid";
import PropTypes from "prop-types";

class InputField extends BaseComponent {
  constructor(props) {
    super(props);
    this.reference = createRef();
  }

  getValue() {
    return this.reference.current.value;
  }

  setValue(value){
    this.reference.current.value = value;
  }

  render() {
    let inputFieldId = this.props.id !== undefined ? this.props.id : shortid();
    let classes = 'p-2 rounded-lg border outline-colors-amber-800';
    if (this.props.classes) {
      classes += ' ' + this.props.classes;
    }
    let containerClasses = '';
    if (this.props.containerClasses) {
      containerClasses = this.props.containerClasses;
    }
    let inputField;
    if (this.props.multiline) {
      inputField =
        <textarea ref={this.reference} id={inputFieldId} className={classes + ' min-h-[150px]'}  />
    } else {
      inputField = <input ref={this.reference} id={inputFieldId} className={classes} {...this.props} />
    }

    return <div className={'flex flex-col gap-0.5 ' + containerClasses}>
      <label htmlFor={inputFieldId} className='text-sm text-[#62646d]'>{this.props.label}</label>
      {inputField}
    </div>
  }
}

InputField.propTypes = {
  multiline: PropTypes.bool, containerClasses: PropTypes.string, classes: PropTypes.string, label: PropTypes.string
}

export default InputField;
