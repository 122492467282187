import BaseComponent from "../../components/BaseComponent";
import DateDiff from 'date-diff';
import React, {createRef} from "react";
import {Pause, Play, Trash, Trash2} from "react-feather";
import CM from "../../lib/system/clientmanager/CM";
import InputField from "../../lib/components/inputFields/InputField";

export default class TimeRecorder extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      result: 0, grid: [], timerStarted: false, currentSpentTime: '00:00:00'
    }
    this.taskInputRef = createRef();
    this.currentSpentTimeInputRef = createRef();
    this.timerInterval = null;

    let grid = CM.getFromLocalStorage('timeGrid');
    this.convertRecords(grid);
  }

  startTimeRecording(e) {
    if (!this.state.timerStarted) {
      this.state.timerStarted = true;
      this.state.grid.push({start: new Date(), stop: null, task: this.taskInputRef.current.getValue()});
      this.state.timerStarted = true;
      this.setState(this.state, this.startTimerInterval);
    }
  }

  stopTimeRecording(e) {
    if (this.state.timerStarted) {
      clearInterval(this.timerInterval);
      let element = this.state.grid.pop();
      element.task = this.taskInputRef.current.getValue();
      this.taskInputRef.current.setValue('');
      element.stop = new Date();
      this.state.grid.push(element);
      CM.addToLocalStorage('timeGrid', JSON.stringify(this.state.grid));
      this.state.timerStarted = false;
      this.setState({...this.state, currentSpentTime: '00:00:00'});
    }
  }

  startTimerInterval() {
    this.timerInterval = setInterval(() => {
      if (this.state.timerStarted) {
        let startDate = new Date(this.state.grid[this.state.grid.length - 1].start);
        let diff = new DateDiff(new Date(), startDate);
        let diffResult = this.getDiffResult(diff);
        this.setState({currentSpentTime: diffResult});
      }
    }, 1000);
  }

  convertRecords(records) {
    if (records != null) {
      records = JSON.parse(records);
      this.state.grid = records.map((record) => {
        let newRecord = record;
        newRecord.start = new Date(record.start);
        newRecord.stop = new Date(record.stop);
        return newRecord;
      })
    }
  }


  getFormattedDiff(diff) {
    let hours = String(Math.floor(diff.hours())).padStart(2, '0');
    let minutes = String(Math.floor(diff.minutes() % 60)).padStart(2, '0');
    let seconds = String(Math.floor(diff.seconds() % 60)).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  getDiffResult(diff) {
    this.state.result += diff.seconds();
    return this.getFormattedDiff(diff);
  }

  render() {
    this.state.result = 0;
    let options = {
      hour: 'numeric', minute: 'numeric', hour12: false // Optionally, if you want to use 24-hour format
    };
    let items = this.state.grid.map((record, index) => {
      let startDate = new Date(record.start);
      let endDate = record.stop !== null ? record.stop : new Date();
      let diff = new DateDiff(endDate, record.start);
      let diffResult = this.getDiffResult(diff);

      return (<tr key={record.start} className='grid grid-cols-12 gap-3 p-3 border-t even:bg-[#ddd]'>
        <td className='col-span-5'>
          <div>{record.task}</div>
        </td>
        <td className='col-span-2'>
          <div>
            {startDate.toLocaleTimeString('en', options)}
          </div>
        </td>
        <td className='col-span-2'>
          <div>
            {record.stop !== null && endDate.toLocaleTimeString('en', options)}
          </div>
        </td>
        <td className='col-span-2'>
          <div>{diffResult}</div>
        </td>
        <td className='col-span-1'>
          <div className='flex justify-end w-full'>
            <Play onClick={(e)=>{
              this.taskInputRef.current.setValue(record.task);
              this.startTimeRecording();
            }
            } className='p-0.5 rounded-full hover:text-colors-cyan-700 hover:text-colors-white hover:transition' />
            <Trash onClick={(e) => {
              this.stopTimeRecording(e);
              this.state.grid.splice(index, 1);
              this.setState(this.state);
              CM.addToLocalStorage('timeGrid', JSON.stringify(this.state.grid));
            }} className='p-0.5 rounded-full hover:text-colors-red-600 hover:transition'/>
          </div>
        </td>
      </tr>);
    });

    return (<div className='min-h-screen flex flex-col items-center my-20'>
      <div className='p-3 flex gap-3 w-full'>
        <InputField ref={this.taskInputRef} placeholder='Tätigkeit' classes='w-full' containerClasses='w-full'/>
        <InputField ref={this.currentSpentTimeInputRef} value={this.state.currentSpentTime} classes='w-28 text-center' readOnly/>
        {!this.state.timerStarted ?
          <button className='bg-colors-cyan-700 text-colors-white p-2 rounded' onClick={(e) => this.startTimeRecording(
            e)}>
            <Play/>
          </button> :
          <button className='bg-colors-red-600 text-colors-white p-2 rounded' onClick={(e) => this.stopTimeRecording(
            e)}>
            <Pause/>
          </button>}
        <button className='bg-colors-red-700 text-colors-white p-2 rounded' onClick={(e) => {
          this.stopTimeRecording(e);
          localStorage.clear();
          this.state.grid = [];
          this.setState(this.state);
        }}>
          <Trash2  />
        </button>
      </div>
      <div className='p-3 w-full'>
        <table className='min-h-full border-collapse w-full border'>
          <thead>
          <tr className='text-left grid grid-cols-12 gap-3 p-2'>
            <th className='col-span-5'>Task</th>
            <th className='col-span-2 '>Started at:</th>
            <th className='col-span-2'>Stopped at:</th>
            <th className='col-span-2 truncate'>Difference</th>
            <th className='col-span-1 truncate'>Action</th>
          </tr>
          </thead>
          <tbody className='h-full'>
          {items.reverse()}
          <tr className='grid grid-cols-12 gap-3 p-3 border-t even:bg-[#ddd]'>
            <td className='col-span-4'>
              <div className='font-bold'>Total</div>
            </td>
            <td className='col-span-3'>
              <div></div>
            </td>
            <td className='col-span-3'>
              <div></div>
            </td>
            <td className='col-span-2'>
              <div className='font-bold'>{Math.round(this.state.result / 60)} min</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>);
  }
}
