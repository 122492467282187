export default class CM {

  static instance;

  static get() {
    if (this.instance === undefined) {
      this.instance = new this();
    }
    return this.instance;
  }


  static addToLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  static getFromLocalStorage(key) {
    return localStorage.getItem(key);
  }

  getTheme(){
    return 'dark'
  }
}