import BaseComponent from "../../components/BaseComponent";
import VerticalLayout from "../../components/layouts/VerticalLayout";
import DateDiff from 'date-diff';
import InputField from "../../lib/components/inputFields/InputField";
import PrimaryButton from "../../lib/components/buttons/PrimaryButton";
import {Plus, Trash} from "react-feather";

export default class TimeCalculator extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            totalDifference: 0, entries: [{startTime: '', endTime: '', difference: 0}]
        };
    }

    addNewEntry() {
        const newEntry = {startTime: '', endTime: '', difference: 0};
        this.setState(prevState => ({
            entryCount: prevState.entryCount + 1, entries: [...prevState.entries, newEntry]
        }));
    }

    validateTime(time) {
        return time !== '';
    }

    calculateDifference(index) {
        const {entries} = this.state;
        const startTime = entries[index].startTime;
        const endTime = entries[index].endTime;
        if (this.validateTime(startTime) && this.validateTime(endTime)) {
            let start = this.getHoursAndMinutes(startTime);
            const startDate = new Date();
            startDate.setHours(start.hour);
            startDate.setMinutes(start.minute);
            startDate.setSeconds(0);
            const endDate = new Date();
            let end = this.getHoursAndMinutes(endTime);
            endDate.setHours(end.hour);
            endDate.setMinutes(end.minute);
            endDate.setSeconds(0);

            const diff = new DateDiff(endDate, startDate);
            entries[index].difference = diff.minutes();
            this.setState({entries});
            this.updateTotalDifference();
        }
    }

    getHoursAndMinutes(time) {
        let splitTime = time.split(':');
        return {hour: parseInt(splitTime[0]), minute: parseInt(splitTime[1])};
    }

    updateTotalDifference() {
        const {entries} = this.state;
        let totalDifference = 0;
        entries.forEach(entry => {
            totalDifference += entry.difference;
        });
        totalDifference = this.decimalToTime(totalDifference)
        this.setState({totalDifference});
    }

    updateStartTime(index, targetInput) {
        let value = targetInput.value;

        const {entries} = this.state;
        entries[index].startTime = value;
        this.setState({entries});
    }

    updateEndTime(index, targetInput) {
        console.log(targetInput.value)
        const {entries} = this.state;
        entries[index].endTime = targetInput.value;
        this.calculateDifference(index);
        this.setState({entries});
    }

    decimalToTime(minutes) {

        let hours = Math.floor(minutes / 60);

        // Separate the integer part (hours) and the decimal part (fraction of an hour)
        let remainingMinutes = Math.round(((minutes / 60) - hours) * 60);

        // Return the formatted time in "H:MM" format
        return `${hours}h:${remainingMinutes.toString().padStart(2, '0')}m`;
    }

    render() {
        const {entries, totalDifference} = this.state;

        return (<VerticalLayout className="min-h-screen items-center justify-center">
            <p className='text-lg font-bold'>Total Zeit: {totalDifference}</p>
            <div className="grid grid-cols-4 items-center border rounded-xl gap-4 p-2">
                {entries.map((entry, index) => (<>
                    <InputField type='time' defaultValue={entry.startTime}
                                onBlur={(e) => this.updateStartTime(index, e.target)}/>
                    <InputField type='time' defaultValue={entry.endTime}
                                onBlur={(e) => this.updateEndTime(index, e.target)}/>

                    <p className='text-center'>{this.decimalToTime(entry.difference)}</p>
                    {index > 0 ? <div className='col-span-1 flex justify-center'>
                        <button onClick={(e) => {
                            this.state.entries.splice(index, 1);
                            this.setState(this.state);
                        }} className='bg-colors-red-600 rounded-lg p-0.5'><Trash className='p-0.5 text-colors-white'/>
                        </button>
                    </div> : <></>}
                    <hr className='col-span-full last:hidden'/>
                </>))}
            </div>
            <PrimaryButton onClick={() => this.addNewEntry()}><Plus/></PrimaryButton>
        </VerticalLayout>);
    }
}
