import {Component, createRef} from 'react';

class BaseComponent extends Component {
  constructor(props) {
    super(props);
    this.reference = createRef();
  }

  getRef() {
    return this.reference;
  }
}

export default BaseComponent;
