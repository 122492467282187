import PropTypes from 'prop-types';
import Layout from './Layout';
import shortid from "shortid";

class GridLayout extends Layout {

  getContent() {
    let id = this.props.id ? this.props.id : shortid();
      return <div id={id} className={'grid w-full p-3 ' + this.props.className}>{this.props.children}</div>;
  }
}

GridLayout.propTypes = {
  content: PropTypes.node, className: PropTypes.string
}

export default GridLayout;
