import PropTypes from 'prop-types';
import Layout from './Layout';
import shortid from "shortid";

class HorizontalLayout extends Layout {

  getContent() {
    let id = this.props.id ? this.props.id : shortid();
    return <div id={id} className={'flex w-full gap-x-3 ' + this.props.className}>{this.props.children}</div>;
  }
}

HorizontalLayout.propTypes = {
  content: PropTypes.node, className: PropTypes.string
}

export default HorizontalLayout;
