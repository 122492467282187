export default function ToolCard(props) {

  return <a href={props.link} className='w-60 h-80 border rounded-xl overflow-hidden'>
    <div className="text-center p-2">
      <h4 className="font-light">
        {props.header}
      </h4>
      <p className="font-light text-sm">
        {props.description}
      </p>
    </div>
    <img className='' src={props.image}/>
  </a>
}