import React from 'react';
import {Circle} from 'react-feather';
import BaseComponent from '../BaseComponent';

export default class NavBar extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            theme: localStorage.getItem('color-theme'), isNavOpen: false
        }
    }

    changeTheme() {
        document.documentElement.classList.toggle('dark');
        if (this.state.theme === 'dark') {
            localStorage.setItem('color-theme', 'light');
            this.setState({theme: 'light'});
        } else {
            localStorage.setItem('color-theme', 'dark');
            this.setState({theme: 'dark'});
        }
    }

    componentDidMount() {
        document.getElementById('navbar').addEventListener('click', (e) => {
        });
    }

    render() {
        let navList = (<ul id='navbar'
                           className='flex justify-center items-end gap-5 p-3 text-lg font-thin bg-colors-white rounded-full px-5 mx-6'>
            <li className='p-1 rounded'>
                <a href='/#aboutPage' className='flex items-center gap-3'>About</a>
            </li>
            <li className='p-1 rounded transition'>
                <a href='/#workPage' className='flex items-center gap-3' rel='noreferrer'>Work</a>
            </li>
            <li className='p-1 rounded transition'>
                <a href='/tools' className='flex items-center gap-3' rel='noreferrer'>Tools</a>
            </li>
        </ul>);

        return <div className='fixed top-2 left-0 right-0 z-20  animate__animated animate__fadeInDown'>
            {navList}
        </div>;
    }
}
