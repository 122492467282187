import PropTypes from 'prop-types';
import Layout from './Layout';

class GridItem extends Layout {

  getContent() {
    return <div className={'cols-pan-1 w-full ' + this.props.className}>{this.props.children}</div>;
  }
}

GridItem.propTypes = {
  content: PropTypes.node, className: PropTypes.string
}

export default GridItem;
