import BaseComponent from "../../components/BaseComponent";
// App.js

import React, {Component} from 'react';

export default class PeriodicTable extends BaseComponent {
  // App.js

  constructor(props) {
    super(props);
    this.state = {
      elements: [], selectedElement: null
    };
  }

  componentDidMount() {
    fetch('https://periodic-table-api.herokuapp.com/elements')
      .then(response => response.json())
      .then(data => {
        this.setState({elements: data.elements});
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  handleClick = (element) => {
    this.setState({selectedElement: element});
  }

  render() {
    const {elements, selectedElement} = this.state;

    return (<div className="periodic-table">
      {elements.map(element => (<div
        key={element.number}
        className={`element ${selectedElement === element ? 'selected' : ''}`}
        style={{gridRow: element.ypos, gridColumn: element.xpos}}
        onClick={() => this.handleClick(element)}>
        <div className="number">{element.number}</div>
        <div className="symbol">{element.symbol}</div>
        <div className="name">{element.name}</div>
      </div>))}
    </div>);
  }
}

