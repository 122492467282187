import BaseComponent from "../../../components/BaseComponent";

class PrimaryButton extends BaseComponent {
  constructor(props) {
    super(props);

  }

  render() {
    return <button className='bg-colors-amber-800 p-2 rounded-lg outline-colors-amber-400 text-colors-white' {...this.props}>{this.props.children}</button>
  }


}

export default PrimaryButton;