import 'aos/dist/aos.css';
import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AboutMePage from "./../../pages/AboutMePage";
import NotFound from "../../pages/NotFound";
import {ParallaxProvider} from "react-scroll-parallax";
import NavBar from "../../components/navigation/NavBar";
import Footer from "../../components/footer/Footer";
import ToolsPage from "../../pages/ToolsPage";
import TimeCalculator from "../../pages/tools/TimeCalculator";
import TimeRecorder from "../../pages/tools/TimeRecorder";
import PeriodicTable from "../../pages/tools/PeriodicTable";
import GradientImage from "../../pages/gradient.png";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: 'LandingPage'
    };
  }

  render() {
    return <div className='min-h-screen'>
      <div id='fixedBg' className='fixed inset-0 flex justify-center bg-[#eeeff1] -z-40 items-center'>
        <img className='' src={GradientImage} alt=''/>
      </div>
      <ParallaxProvider>
        <NavBar/>
        <Router>
          <Routes>
            <Route index element={<AboutMePage/>}/>
            <Route path="/tools" element={<ToolsPage/>}>
            </Route>
            <Route path='/tools/timecalculator' element={<TimeCalculator/>}/>
            <Route path='/tools/timerecorder' element={<TimeRecorder/>}/>
            <Route path='/tools/periodictable' element={<PeriodicTable/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </Router>
        <Footer/>
      </ParallaxProvider>
    </div>
  }
}

export default App;
