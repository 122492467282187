import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/view/App';
import reportWebVitals from './reportWebVitals';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CM from "./lib/system/clientmanager/CM";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <ToastContainer
      position='top-right'
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      rtl={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={CM.get().getTheme()}/>
    <App/>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
