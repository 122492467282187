import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";

class Layout extends BaseComponent {

  getContent() {
    return this.props.content;
  }

  render() {
    return this.getContent()
  };
}

Layout.propTypes = {
  content: PropTypes.node
}

export default Layout;
