import BaseComponent from "../../components/BaseComponent";
import {createRef} from "react";
import VerticalLayout from "../../components/layouts/VerticalLayout";
import HorizontalLayout from "../../components/layouts/HorizontalLayout";
import InputField from "../../lib/components/inputFields/InputField";
import PrimaryButton from "../../lib/components/buttons/PrimaryButton";

class Contact extends BaseComponent {

  constructor(props) {
    super(props);
    this.emailRef = createRef()
    this.nameRef = createRef();
    this.messageRef = createRef();
  }

  onContactSubmitClick(e) {
    let mail = 'mailto:' + this.emailRef.current.getValue() + '?subject=' + this.nameRef.current.getValue() + '&body=' +
      this.messageRef.current.getValue();
    let a = document.createElement('a');
    a.href = mail;
    a.click();
  }

  render() {
    return <VerticalLayout id='contactPage' className='max-w-4xl mx-auto px-4 min-h-screen justify-center'>
      <HorizontalLayout className='border border-colors-gray-900 rounded-xl p-4 flex-wrap md:flex-nowrap'>
        <h1 className='w-full text-4xl self-center'>
          Wanna get in touch?
        </h1>
        <VerticalLayout>
          <VerticalLayout className='md:flex-row md:justify-between md:gap-2'>
            <InputField ref={this.nameRef} placeholder='Enter your name' type='text' label={'Name'}/>
            <InputField ref={this.emailRef} placeholder='Enter your email' label={'Email Address'} type='text'/>
          </VerticalLayout>
          <InputField ref={this.messageRef} multiline placeholder='What do you want to write me about?' label={'Message'}/>
          <PrimaryButton onClick={(e) => this.onContactSubmitClick(e)}>Submit</PrimaryButton>
        </VerticalLayout>
      </HorizontalLayout>
    </VerticalLayout>
  }

}

export default Contact;