import BaseComponent from "../BaseComponent";
import HorizontalLayout from "../layouts/HorizontalLayout";

class Footer extends BaseComponent {

  render() {
    return <HorizontalLayout className='gap-4 p-4 fixed bottom-0 left-0 right-0 z-20 justify-center italic font-serif bg-colors-white'>
      Created with ❤️ by Farzad Habibi
    </HorizontalLayout>
  }
}

export default Footer;