import PropTypes from "prop-types";
import Layout from "./Layout";
import shortid from "shortid";

class VerticalLayout extends Layout {

  getContent() {
    let id = this.props.id ? this.props.id : shortid();

    return <div {...this.props} id={id} className={'flex flex-col w-full gap-y-3 ' + this.props.className}>{this.props.children}</div>;
  }
}

VerticalLayout.propTypes = {
  content: PropTypes.node, className: PropTypes.string
}

export default VerticalLayout;
